import { Address } from './address';
import { Country } from './country';

export class Store {

  // tslint:disable-next-line:max-line-length
  constructor(id: number, email: string, zipCode: string, address: string, city: string, phone: string, website: string, latitude: string, longitude: string, googleMapsLink: string, paymentOnline: boolean, storeDelivery: boolean, geoLimitation: string, openingTime: string, label: string, copyEnabled: boolean, copyOpts: string) {
    this.id = id;
    this.email = email;
    this.zipCode = zipCode;
    this.address = address;
    this.city = city;
    this.phone = phone;
    this.website = website;
    this.latitude = latitude;
    this.longitude = longitude;
    this.googleMapsLink = googleMapsLink;
    this.paymentOnline = paymentOnline;
    this.storeDelivery = storeDelivery;
    this.geoLimitation = geoLimitation;
    this.openingTime = openingTime;
    this.label = label;
    this.copyEnabled = copyEnabled;
    this.copyOpts = copyOpts;
  }
  id: number;
  email: string;
  zipCode: string;
  address: string;
  city: string;
  phone: string;
  website: string;
  latitude: string;
  longitude: string;
  googleMapsLink: string;
  paymentOnline: boolean;
  storeDelivery: boolean;
  geoLimitation: string;
  openingTime: string;
  label: string;
  copyEnabled: boolean;
  copyOpts: string;

  static Hydrate(object): Store {
    // tslint:disable-next-line:max-line-length
    //console.log(object)
    return new Store(object.id, object.email, object.zipCode, object.address, object.city, object.phone, object.website, object.latitude, object.longitude, object.googleMapsLink, object.paymentOnline, object.storeDelivery, object.geoLimitation, object.openingTime, object.label, object.copyEnabled, object.copyOpts);
  }

  getLatitude(): number {
    return parseFloat(this.latitude);
  }

  getLongitude(): number {
    return parseFloat(this.longitude);
  }

  getGeoLimitation(): string {
    if (this.geoLimitation === '') {
      return null;
    }

    return this.geoLimitation;
  }

  getMailTo(): string {
    return `mailto:${this.email}`;
  }

  getTel(): string {
    return `tel:${this.phone}`;
  }

  openGoogleMaps() {
    window.open(this.googleMapsLink);
  }

  isVirtual(): boolean {
    return this.address === '';
  }

  getWebsiteUrl(): string {
    return `https://${this.website}`;
  }

  getLogoSrc(): string {
    if (this.isVirtual()) {
      return './assets/svg/logo_mobile.svg';
    }

    if (this.storeDelivery) {
      return './assets/svg/logo_partenaire.svg';
    }

    return './assets/svg/logo.svg';
  }

  convertToAddress(country: Country): Address {
    // tslint:disable-next-line:max-line-length
    return new Address(null, this.address, this.city, this.label, country, null, 'COMPANY', this.label, null, null, this.phone, this.zipCode, false);
  }
}
