import { Component, Input, OnInit } from '@angular/core';
import { Client } from '../models/client';
import { Store } from '../models/store';
import { Address } from '../models/address';
import { Country } from '../models/country';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { AddressService } from '../services/address';
import { GenderService } from '../services/gender';
import { CountryService } from '../services/country';
import { StoreService } from '../services/store';
import { getTreeNoValidDataSourceError } from '@angular/cdk/tree';



@Component({
  // tslint:disable-next-line:component-selector
  selector: 'page-signup-details',
  templateUrl: 'signup-details.html',
})
export class SignupDetailsPage implements OnInit{
  @Input() viewCtrl: ModalController;

  client: Client;
  address: Address;
  clientId: number;
  firstAddress: boolean;
  store: Store;

  countries: Country[];
  genders: {key: string, value: string}[] = [];
  department: string;

  // tslint:disable-next-line:max-line-length
  constructor(private storeService: StoreService, private countryPrvd: CountryService, private navParams: NavParams, private addressPrvd: AddressService, private genderPrvd: GenderService, private toastCtrl: ToastController) {
    this.client = this.navParams.get('client');
    this.address = this.navParams.get('address');
    this.clientId = this.navParams.get('clientId');
    this.firstAddress = this.navParams.get('firstAddress');


    if (!this.address) {
      this.address = new Address(null, null, null, this.client.company, new Country(), this.client.firstname, this.client.gender, 'Adresse principale', this.client.lastname, null, null, null, null);

    }
  }

  ngOnInit() {
    this.storeService.getStoreByClientEmail(this.client.email).subscribe(store => {
      this.store = store;
      this.department = this.store.getGeoLimitation();
    });

    if (this.department) {
      this.address.zipCode = this.department;
    }

    this.genderPrvd.getGenders().then(genders => {
      genders.forEach((value, key) => {
        this.genders.push({key, value});
        }
      );
      this.address.gender = this.genders[0].key;
      //console.log(genders);
    });
    this.countryPrvd.getCountries().then(countries => {
      this.countries = countries;
      //console.log(this.countries);
      countries.forEach(c => {
        if (c.name === 'FRANCE') {
          this.address.country.id = c.id;
        }
      });
    });
  }

  log(){
    console.log(this.genders);
    console.log(this.countries);
  }

  async save() {

    if (this.clientId) {
      this.addressPrvd.setAddress(this.clientId, this.address).then(a => this.viewCtrl.dismiss({address: a}));
    } else {
      this.addressPrvd.addAddresse(this.client.id, this.address).then(a => this.viewCtrl.dismiss({address: a}));
    }
  }

  cancel() {
    this.viewCtrl.dismiss();
  }

}
