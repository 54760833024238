import { CityProvider } from './services/city';
import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonApp, IonicModule, IonicRouteStrategy, ModalController, NavParams } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import localeFr from '@angular/common/locales/fr'
import { AppComponent } from './app.component';
import { CovalentLayoutModule } from '@covalent/core/layout';
import { CovalentStepsModule } from '@covalent/core/steps';
import { CovalentHttpModule } from '@covalent/http';
import { CovalentHighlightModule } from '@covalent/highlight';
import { CovalentDynamicFormsModule } from '@covalent/dynamic-forms';
import { CovalentMarkdownModule } from '@covalent/markdown';
import { CovalentBaseEchartsModule } from '@covalent/echarts/base';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { PayPal, PayPalPayment, PayPalConfiguration } from '@ionic-native/paypal/ngx';
import { ConnectionBackend, Http, HttpModule } from '@angular/http';
import { ClientService } from './services/client';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { UserService } from './services/user';
import { FranchiseService } from './services/franchise';
import { PartnerService } from './services/partner';
import { AddressService } from './services/address';
import { CopyService } from './services/copy';
import { StoreService } from './services/store';
import { MatRippleModule } from '@angular/material/core';
import { CountryService } from './services/country';
import { GenderService } from './services/gender';
import { SignupDetailsPage } from './signup-details/signup-details';
import { FormsModule } from '@angular/forms';
import { AddressModalComponent } from './address-modal/address-modal.component';
import { SalesTermsPage } from './sales-terms/sales-terms';
import { ErrorProvider } from './services/error';
import { CodeInputModule } from 'angular-code-input';

registerLocaleData(localeFr, 'fr');
@NgModule({
  declarations: [AppComponent, SignupDetailsPage, AddressModalComponent, SalesTermsPage],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, CovalentLayoutModule,
    CovalentStepsModule, CovalentHttpModule.forRoot(),
    CovalentHighlightModule,
    CovalentMarkdownModule,
    CovalentDynamicFormsModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    CodeInputModule,
    HttpModule,
    CovalentBaseEchartsModule, MatRippleModule, FormsModule],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: LOCALE_ID, useValue: 'fr-FR'},
    PayPal,
    ClientService,
    IonApp,
    UserService,
    FranchiseService,
    PartnerService,
    AddressService,
    CopyService,
    StoreService,
    CountryService,
    GenderService,
    NavParams,
    ErrorProvider,
    CityProvider
  ],
  bootstrap: [AppComponent],
  exports: [
  ]
})
export class AppModule {}
// palete : https://www.canva.com/colors/color-palettes/facing-forward/
