import { HttpService } from './http';
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { ToastController, IonApp } from '@ionic/angular';
import { Client } from '../models/client';
import { Config } from '../config';
import { App } from '@capacitor/core';
import { ForgotPassword } from '../models/forgot-password';
import { GeneratePassword } from '../models/generate-password';

@Injectable()

export class ClientService extends HttpService {

  constructor(private http: Http, protected toastCtrl: ToastController, private app: IonApp) {
    super(toastCtrl);
  }

  static GetCurrentClient() {
    return Client.Hydrate(JSON.parse(localStorage.getItem('client')));
  }

  signUp(client: Client, sid: number): Promise<Client> {
    const ressource = `stores/${sid}/clients`;

    // client.appName = localStorage.getItem('appName');

    // @ts-ignore
    return this.http.post(Config.BuildPath(ressource), client)
      .toPromise()
      .then(response => {
        const json = response.json();

        if (!json) {
          return null;
        }

        const c: Client = Client.Hydrate(json);

        localStorage.setItem('version', Config.version.toString());
        localStorage.setItem('client', JSON.stringify(c));
        localStorage.setItem('token', c.userToken.token);

        return c;
      })
      .catch(error => this.handleError(error));
  }

  getConnectedClient(): Client {
    let c: Client;

    const storage = localStorage.getItem('client');
    if (storage) {
      const json = JSON.parse(storage);

      if (!json) {
        return c;
      }

      c = Client.Hydrate(json);

      if (!c.userToken.isValidTTL()) {
        this.logout(c);
        // this.app.get().setRoot(HomePage);

        return null;
      }
    }

    return c;
  }

  isSignedUp(email: string): Promise<boolean> {
    const ressource = 'clients/exist';

    return this.http.put(Config.BuildPath(ressource), JSON.stringify(email))
      .toPromise()
      .then(response => {
        return response.json();
      })
      .catch(error => this.handleError(error));
  }

  logout(client: Client): void {
    if (client) {
      this.removeDeviceToken().then(() => {
        localStorage.removeItem('client');
        localStorage.removeItem('token');
      });
    }
  }

  hasForgottenPassword(fp: ForgotPassword): Promise<boolean> {
    const ressource = 'clients/forgot/password';

    // @ts-ignore
    return this.http.post(Config.BuildPath(ressource), fp)
      .toPromise()
      .then(() => {
        return Promise.resolve(true);
      })
      .catch(error => this.handleError(error));
  }

  generateNewPassword(gp: GeneratePassword): Promise<Client> {
    const ressource = 'clients/generate/password';

    // @ts-ignore
    return this.http.put(Config.BuildPath(ressource), gp)
      .toPromise()
      .then(response => {
        const json = response.json();

        if (!json) {
          return null;
        }

        const c: Client = Client.Hydrate(json);

        localStorage.setItem('version', Config.version.toString());
        localStorage.setItem('client', JSON.stringify(c));
        localStorage.setItem('token', c.userToken.token);

        return c;
      })
      .catch(error => this.handleError(error));
  }

  removeDeviceToken(): Promise<boolean> {
    let c: Client;
    const storage = localStorage.getItem('client');
    if (storage) {
      const json = JSON.parse(storage);
      c = Client.Hydrate(json);
    }

    const ressource = `clients/${c.id}/device/token/${localStorage.getItem('deviceToken')}`;

    // @ts-ignore
    return this.http.delete(Config.BuildPath(ressource))
      .toPromise()
      .then(() => {
        return Promise.resolve(true);
      })
      .catch(error => this.handleError(error));
  }
}
