import { Injectable } from '@angular/core';
import { GENDERS } from '../enums/gender';


@Injectable()

export class GenderService {

  constructor() {}

  getGenders(): Promise<Map<string, string>> {
    return Promise.resolve(GENDERS);
  }
}
