export class Config {

  static version = 5;
  static gouvGeoApiUrl = "https://geo.api.gouv.fr/communes";


  static protocol = 'https';
  static dns = 'api.pointdencre.fr';
  // static dns = '192.168.1.12:54780';
  static url = 'belfort.pointdencre.fr';

  static socialNetwork: any = {
    facebook: {
      appId: '1938061609806592',
      xfbml: true,
      version: 'v2.9',
      scope: 'email,user_birthday'
    },
    google: {
      apiKey: 'AIzaSyC-9EwRtvm9iXuKM44m3Yde135--K2x3YM',
      clientId: '922664366751-91uiubch7fskpd0jpubs25oqldju764h.apps.googleusercontent.com',
      scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/user.birthday.read'
    }
  };

  static firebase: any = {
    apiKey: 'AIzaSyCowYBa40cH91W3-ROTvlUZoOfWFSwy8Vc',
    authDomain: 'appli-mobile-e55ac.firebaseapp.com',
    databaseURL: 'https://appli-mobile-e55ac.firebaseio.com',
    projectId: 'appli-mobile-e55ac',
    storageBucket: 'appli-mobile-e55ac.appspot.com',
    messagingSenderId: '922664366751'
  };

  static adyenKey = '10001|C918E79D65317BB67721101050BAB939BF66B28E09F3D1FA59AD06EA3058C9825AD5D79799737D131CDA376BAA064DD90FBAD02F6942E376C24599406AFE68B554E695BA6107B2459A56B13CDEB600457D1D12D34006B746838CFD01F0636BB38101BB521871694506719222F095D81C5F4E8DE36DEEB5BF25E5DB4E37FAFBEA033D1914BF5709917F69B7CF6D148C6E7A65EE158F513EC3FEA903EEFBE8C64A659EA096EEAD3A7FAC0146E1E57D044DE4FABF3B43DED8E2F1D33C57204218C4D2CE93BBB189BE58ACE01FE75C8BB24CA6EBC020225220B8286EAF65643C22036195242C860C19675FAC34C1D30B6E5FF070610E035C88339C2CA179DEAD168D';

  static BuildPath(resource: string): string {
    return `${Config.protocol}://${Config.dns}/${resource}`;
    // return `${Config.dns}/${resource}`;
  }

  static isProtocol(protocol: string): boolean {
    return location.protocol === `${protocol}:`;
  }

  static getItem(store: string) {
    return '';
  }
}
