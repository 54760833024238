import { UserToken } from './user-token';
import { Address } from './address';
import { Country } from './country';

export class Client {

  // tslint:disable-next-line:max-line-length
  constructor(id: number = null, userToken: UserToken = null, birthdayDate: Date = null, company: string = null, email: string = null, confirmEmail: string = null, firstname: string = null, gender: string = null, inscriptionDatetime: Date = null, lastname: string = null, password: string = null, confirmPassword: string = null, facebookId: string = null, googleId: string = null) {
    this.id = id;
    this.userToken = userToken;
    this.birthdayDate = birthdayDate;
    this.company = company;
    this.email = email;
    this.confirmEmail = confirmEmail;
    this.firstname = firstname;
    this.gender = gender;
    this.inscriptionDatetime = inscriptionDatetime;
    this.lastname = lastname;
    this.password = password;
    this.confirmPassword = confirmPassword;
    this.facebookId = facebookId;
    this.googleId = googleId;
  }
  id: number;
  userToken: UserToken;
  birthdayDate: Date;
  company: string;
  email: string;
  confirmEmail: string;
  firstname: string;
  gender: string;
  inscriptionDatetime: Date;
  lastname: string;
  password: string;
  confirmPassword: string;
  facebookId: string;
  googleId: string;

  static Hydrate(object): Client {
    const bd: Date = new Date(object.birthdayDate);
    const idt: Date = new Date(object.inscriptionDatetime.replace(' ', 'T'));

    const ut: UserToken = UserToken.Hydrate(object.userToken);

    // tslint:disable-next-line:max-line-length
    return new Client(object.id, ut, bd, object.company, object.email, object.confirmEmail, object.firstname, object.gender, idt, object.lastname, object.password, object.confirmPassword, object.facebookId, object.googleId);
  }

  static HydrateFromGoogleProfil(object: any): Client {
    // tslint:disable-next-line:max-line-length
    return new Client(null, null, null, null, object.getEmail(), object.getEmail(), object.getGivenName(), null, null, object.getFamilyName(), null, null, null, object.getId());
  }

  static HydrateFromFacebookProfil(object: any): Client {
    // tslint:disable-next-line:max-line-length
    return new Client(null, null, new Date(object.birthday), null, object.email, object.email, object.first_name, null, null, object.last_name, null, null, object.id, null);
  }

  getFullName(): string {
    return `${this.firstname} ${this.lastname}`;
  }

  isCompany(): boolean {
    // tslint:disable-next-line:triple-equals
    return this.gender == 'COMPANY';
  }

  isValidForSignup(checkBirthdayDate: boolean): boolean {
    if (!this.gender) {
      return false;
    }

    if (this.isCompany() && !this.company) {
      return false;
    }

    if (!this.firstname) {
      return false;
    }

    if (!this.lastname) {
      return false;
    }

    if (checkBirthdayDate && !this.birthdayDate) {
      return false;
    }

    if (!this.email) {
      return false;
    }

    if (!this.confirmEmail) {
      return false;
    }

    if (!this.emailsAreEquivalent()) {
      return false;
    }

    if (!this.password) {
      return false;
    }

    if (!this.confirmPassword) {
      return false;
    }

    if (!this.passwordsAreEquivalent()) {
      return false;
    }

    return true;
  }

  emailsAreEquivalent(): boolean {
    return this.email == this.confirmEmail;
  }

  passwordsAreEquivalent(): boolean {
    return this.password == this.confirmPassword;
  }

  convertToAddress(): Address {
    // tslint:disable-next-line:max-line-length
    return new Address(null, null, null, this.company, new Country(), this.firstname, this.gender, 'Adresse principale', this.lastname, null, null, null, null);
  }
}
