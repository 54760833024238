import { ErrorHandler, Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';


@Injectable()

export class ErrorProvider extends ErrorHandler {

  constructor(private toastCtrl: ToastController) {
    super();
  }

  async handleError(error: any, displayToast: boolean = false) {
    // @TODO: post error to api
    console.log('post error to api', error);

    if (displayToast) {
      (await this.toastCtrl.create({
        message: error.error,
        duration: 3000,
        position: 'top',
      })).present();
    }

    super.handleError(error);
  }

}
