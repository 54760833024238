import { Component, Input, OnInit } from '@angular/core';
import { Address } from '../models/address';
import { ModalController } from '@ionic/angular';
import { Client } from '../models/client';
import { ClientService } from '../services/client';
import { AddressService } from '../services/address';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SignupDetailsPage } from '../signup-details/signup-details';

@Component({
  selector: 'app-address-test',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss'],
})
export class AddressModalComponent implements OnInit {

  constructor(private clientService: ClientService, private addrService: AddressService) { }
  @Input() shippingAddress: Address = new Address();
  @Input() invoiceAddress: Address  = new Address();
  @Input() isShipping: boolean;
  @Input() showAddr: boolean;
  @Input() modalCtrl: ModalController;
  client: Client;
  sameAddress = true;
  addresses: Address[] = [];

  ngOnInit() {
    this.client = JSON.parse(localStorage.getItem('client'));
    this.addrService.getAddresses(this.client.id).then(addresses => {
      this.addresses = addresses;
      if (addresses.length > 0){
        this.invoiceAddress = addresses[0];
        this.shippingAddress = addresses[0];
      }
      //console.log(this.addresses);
    });
  }
  get addressess(){
      //console.log('here');
      return this.addresses;
  }

  async signUpDetails(): Promise<void> {
    const m = await this.modalCtrl.create({
      component: SignupDetailsPage,
      componentProps: {
        client: this.client,
        viewCtrl: this.modalCtrl,
        firstAddress: this.addresses.length === 0,
        clientId: this.addresses.length === 0 ? this.client.id : undefined
      }
     });
    await m.present();
    const test = await m.onWillDismiss();
    //console.log(test);
    if (test.data) {
      this.addresses.push(test.data.address);
    }

  }

  onShippingAddressSelect(adr: Address){
    this.shippingAddress = adr;
  }

  onInvoiceAddressSelect(adr: Address){
    this.invoiceAddress = adr;
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      shippingAddress : this.sameAddress ? this.invoiceAddress : this.shippingAddress,
      invoiceAddress : this.invoiceAddress
    });
  }

}

