export class Country {
  id: number;
  currencyIso: string;
  currencySymbol: string;
  name: string;
  tva: number;

  constructor(id: number = null, currencyIso: string = null, currencySymbol: string = null, name: string = null, tva: number = null) {
    this.id = id;
    this.currencyIso = currencyIso;
    this.currencySymbol = currencySymbol;
    this.name = name;
    this.tva = tva;
  }

  static Hydrate(object): Country {
    return new Country(object.id, object.currencyIso, object.currencySymbol, object.name, object.tva);
  }
}
