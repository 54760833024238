import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '../models/store';
import { HttpService } from './http';
import { map } from 'rxjs/operators';
import { Config } from '../config';



@Injectable()

export class StoreService {

  constructor(private http: HttpClient) {}

  static GetCurrentStore(): Store {
    const json: any = JSON.parse(Config.getItem('store'));
    if (!json) {
      return null;
    }

    return Store.Hydrate(json);
  }

  getStoresAround(latitude: number, longitude: number): Observable<Store[]> {
    const ressource = `${latitude},${longitude}/stores`;

    const ss: Store[] = [];

    return this.http.get<Store[]>(Config.BuildPath(ressource))
      .pipe(map(response => {
        if (!response) {
          return ss;
        }

        for (const object of response) {
          ss.push(Store.Hydrate(object));
        }

        return ss;
      }));
  }

  getStoreByWebsite(website: string): Observable<Store> {
    const ressource = `website/${website}/stores`;

    return this.http.get<Store>(Config.BuildPath(ressource))
      .pipe(map(response => {
        return Store.Hydrate(response);
      }));
  }

  getStoreByClientEmail(email: string): Observable<Store> {
    const ressource = `clients/${email}/stores`;

    return this.http.get<Store>(Config.BuildPath(ressource))
      .pipe(map(response => {
        return Store.Hydrate(response);
      }));
  }

  fetchAllAroundFranchise(zipCode: string): Observable<Store[]> {
    const params = new HttpParams().set('zipCode', zipCode);
    const ressource = `stores`;

    return this.http.get<Store[]>(Config.BuildPath(ressource), { params }).pipe(
            map(shops => shops.map(shop => Store.Hydrate(shop)))
    );
}
}
