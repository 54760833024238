import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { map } from "rxjs/operators";

import { Config } from "../config";

import { City } from "../models/city";
import { Observable } from 'rxjs';

@Injectable()

export class CityProvider {

  constructor(private http: HttpClient) {}

  getCitiesByZipCode(zipCode: string): Observable<City[]> {
    const ressource = `${Config.gouvGeoApiUrl}?fields=nom,centre&codePostal=${zipCode}`;

    let cs: City[] = [];

    return this.http.get<City[]>(ressource)
      .pipe(map(response => {
        if (!response) {
          return cs;
        }

        for (let object of response) {
          cs.push(City.Hydrate(object));
        }

        return cs;
      }));
  }
}
