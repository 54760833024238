import { Injectable } from '@angular/core';

import { PARTNERS } from '../enums/partners';

@Injectable()

export class PartnerService {

  getPartners(): Promise<Map<number, any>> {
    return Promise.resolve(PARTNERS);
  }

  getPartner(): Promise<any> {
    return Promise.resolve(JSON.parse(localStorage.getItem('partner')));
  }

  isExist(): boolean {
    return localStorage.hasOwnProperty('partner');
  }

  isNational(): boolean {
    return JSON.parse(localStorage.getItem('partner')).isNational;
  }

}
