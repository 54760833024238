export class UserToken {
  token: string;
  userId: number;
  ttlDatetime: Date;
  authLevel: string;

  constructor(token: string, userId: number, ttlDatetime: Date, authLevel: string) {
    this.token = token;
    this.userId = userId;
    this.ttlDatetime = ttlDatetime;
    this.authLevel = authLevel;
  }

  isValidTTL(): boolean {
    this.ttlDatetime.setMinutes(this.ttlDatetime.getTimezoneOffset() + this.ttlDatetime.getMinutes());
    return new Date() < this.ttlDatetime;
  }

  static Hydrate(object): UserToken {
    let ttldt: Date = new Date(object.ttlDatetime.replace(" ", "T"));

    return new UserToken(object.token, object.userId, ttldt, object.authLevel);
  }
}
