import { ToastController } from '@ionic/angular';
import { HttpService } from './http';
import { Injectable } from '@angular/core';
import { Address } from '../models/address';
import { Config } from '../config';
import { Http, RequestOptions } from '@angular/http';


@Injectable()

export class AddressService extends HttpService {

  constructor(private http: Http, protected toastCtrl: ToastController) {
    super(toastCtrl);
  }

  getAddresses(clientId: number): Promise<Address[]> {
    const ressource = `clients/${clientId}/addresses`;

    const addresses: Address[] = [];


    // @ts-ignore
    return this.http.get(Config.BuildPath(ressource), new RequestOptions({headers: this.getHeaders(true)}))
      .toPromise()
      .then(response => {
        const json = response.json();

        if (!json) {
          return addresses;
        }

        for (const object of json) {
          addresses.push(Address.Hydrate(object));
        }

        return addresses;
      })
      .catch(error => this.handleError(error));
  }

  addAddresse(clientId: number, address: Address): Promise<Address> {
    const ressource = `clients/${clientId}/addresses`;

    // @ts-ignore
    return this.http.post(Config.BuildPath(ressource), address, new RequestOptions({headers: this.getHeaders(true)}))
      .toPromise()
      .then(response => {
        const json = response.json();

        if (!json) {
          return null;
        }

        return Address.Hydrate(json);
      })
      .catch(error => this.handleError(error));
  }

  setAddress(clientId: number, address: Address): Promise<Address> {
    const ressource = `clients/${clientId}/addresses`;

    // @ts-ignore
    return this.http.put(Config.BuildPath(ressource), address, new RequestOptions({headers: this.getHeaders(true)}))
      .toPromise()
      .then(response => {
        const json = response.json();

        if (!json) {
          return null;
        }

        return Address.Hydrate(json);
      })
      .catch(error => this.handleError(error));
  }

  delAddresse(clientId: number, addressId: number): Promise<boolean> {
    const ressource = `clients/${clientId}/addresses/${addressId}`;

    // @ts-ignore
    return this.http.delete(Config.BuildPath(ressource), new RequestOptions({headers: this.getHeaders(true)}))
      .toPromise()
      .then(() => Promise.resolve(true))
      .catch(error => this.handleError(error));
  }

  getNbAddresse(clientId: number): Promise<number> {
    const ressource = `clients/${clientId}/addresses/number`;

    return this.http.get(Config.BuildPath(ressource), new RequestOptions({headers: this.getHeaders(true)}))
      .toPromise()
      .then(response => {
        return response.json();
      })
      .catch(error => this.handleError(error));
  }

}
