import { HttpService } from './http';
import { forkJoin, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ProductTypeFranchise } from '../models/product-type-franchise';
import { Cart } from '../models/cart';
import { Http, RequestOptions } from '@angular/http';
import { ToastController } from '@ionic/angular';
import { Config } from '../config';
import { Copy } from '../models/copy';
import { Client } from '../models/client';


@Injectable()

export class CopyService extends HttpService {



  constructor(private http: Http, protected toastCtrl: ToastController) {
    super(toastCtrl);
  }

  // tslint:disable-next-line:variable-name
  getCopies(clientId: number = null): Promise<Copy[]> {
    const ressource = `copy/clients/${clientId}`;

    const cart: Copy[] = [];

    // @ts-ignore
    return this.http.get(Config.BuildPath(ressource), new RequestOptions({headers: this.getHeaders(true)}))
      .toPromise()
      .then(response => {
        const json = response.json();

        if (!json) {
          return cart;
        }

        for (const object of json) {
          cart.push(Copy.Hydrate(object));
        }

        return cart;
      })
      .catch(error => this.handleError(error));
  }

  addCopy(clientId: number, copy: Copy): Promise<void|Copy> {
    const ressource = `copy/${clientId}`;
    return this.http.post(Config.BuildPath(ressource), copy, new RequestOptions({headers: this.getHeaders(true)}))
      .toPromise()
      .then(response => {
        const json = response.json();
        if (!json) {
          return null;
        }
        return Copy.Hydrate(json);
      })
      .catch(error => this.handleError(error));
  }
  sendCopyFile(file: File, filename: string): Promise<void|Copy> {
    const ressource = `copy/file/`;
    const formData = new FormData();
    formData.append('file', file, filename);
    return this.http.post(Config.BuildPath(ressource), formData, new RequestOptions({headers: this.getHeaders(true)}))
      .toPromise()
      .then(response => {
        const json = response.json();
        if (!json) {
          return null;
        }
        return JSON.parse(json);
      })
      .catch(error => this.handleError(error));
  }

  sendCopyFiles(files: {file: File, filename: string}[]) {
    const ressource = `copy/file/`;
    const forks: any[] = [];
    files.forEach(file => {
      const formData = new FormData();
      formData.append('file', file.file, file.filename);
      forks.push(this.http.post(Config.BuildPath(ressource), formData, new RequestOptions({headers: this.getHeaders(true)})));
    });
    return forkJoin(forks)
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(error => this.handleError(error));
  }

}
