import { Component, Input } from '@angular/core';
import { Franchise } from '../models/franchise';
import { Store } from '../models/store';
import { ModalController } from '@ionic/angular';
import { StoreService } from '../services/store';
import { FranchiseService } from '../services/franchise';
import { ClientService } from '../services/client';




@Component({
  selector: 'app-page-sales-terms',
  templateUrl: 'sales-terms.html'
})

export class SalesTermsPage {

  @Input() viewCtrl: ModalController;

  franchise: void | Franchise;
  store: Store;

  @Input() showValidation: boolean;

  constructor(public franchiseservice: FranchiseService, private storeService: StoreService) {
    franchiseservice.getFranchise().then(franchise => this.franchise = franchise) ;
    storeService.getStoreByClientEmail('matthieu.jeannin.dev@gmail.com').subscribe(store => {
      this.store = store;
    });

    // if (!ClientService.GetCurrentClient()) {
    //   this.showValidation = true;
    // }
  }

  validSalesTerms(): void {
    this.viewCtrl.dismiss(true);
  }

  cancel(): void {
    this.viewCtrl.dismiss(false);
  }

}
