import { Client } from './client';
import { ProductTypeFranchise } from './product-type-franchise';

export class Copy {
  clientId: number;
  id: number;
  data: string;
  delivery: boolean;
  deliveryPrice: number;
  transporter: string;
  trackingNumber: string;
  status: string;
  shippingAddress: string;
  deliveryAddress: string;
  paypalNumber: string;
  forfait: string;
  forfaitCommandeC: number;
  forfaitCommandeBW: number;
  invoiceNumber: number;
  price: number;
  paymentDate: Date;


  // tslint:disable-next-line:max-line-length
  constructor(id: number, clientId: number, data: string, delivery: boolean, deliveryPrice: number, transporter: string, trackingNumber: string, status: string, shippingAddress: string, deliveryAddress: string, paypalNumber: string, forfait: string, forfaitCommandeC: number, forfaitCommandeBW: number, invoiceNumber: number, price: number, paymentDate: Date) {
    this.clientId = clientId;
    this.data = data;
    this.delivery = delivery;
    this.deliveryPrice = deliveryPrice;
    this.transporter = transporter;
    this.trackingNumber = trackingNumber;
    this.status = status;
    this.shippingAddress = shippingAddress;
    this.deliveryAddress = deliveryAddress;
    this.paypalNumber = paypalNumber;
    this.forfait = forfait;
    this.forfaitCommandeC = forfaitCommandeC;
    this.forfaitCommandeBW = forfaitCommandeBW;
    this.invoiceNumber = invoiceNumber;
    this.price = price;
    this.paymentDate = paymentDate;
    this.id = id;
  }

  static Hydrate(object): Copy {
    // tslint:disable-next-line:max-line-length
    return new Copy(object.id, object.clientId, object.data, object.delivery, object.deliveryPrice, object.transporter, object.trackingNumber, object.status, object.shippingAddress, object.deliveryAddress, object.paypalNumber, object.forfait, object.forfaitCommandeC, object.forfaitCommandeBW, object.invoiceNumber, object.price, object.paymentDate);
  }

}
