export class City {
  name: string;
  latitude: number;
  longitude: number;

  constructor(name: string, latitude: number, longitude: number) {
    this.name = name;
    this.latitude = latitude;
    this.longitude = longitude;
  }

  static Hydrate(object): City {
    return new City(object.nom, object.centre.coordinates[1], object.centre.coordinates[0]);
  }
}
