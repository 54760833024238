import { Country } from './country';

export class Address {
  id: number;
  address: string;
  city: string;
  company: string;
  country: Country;
  firstname: string;
  gender: string;
  label: string;
  lastname: string;
  mobile: string;
  phone: string;
  zipCode: string;
  isDefault: boolean;

  // tslint:disable-next-line:max-line-length
  constructor(id: number = null, address: string = null, city: string = null, company: string = null, country: Country = new Country(), firstname: string = null, gender: string = null, label: string = null, lastname: string = null, mobile: string = null, phone: string = null, zipCode: string = null, isDefault: boolean = null) {
    this.id = id;
    this.address = address;
    this.city = city;
    this.company = company;
    this.country = country;
    this.firstname = firstname;
    this.gender = gender;
    this.label = label;
    this.lastname = lastname;
    this.mobile = mobile;
    this.phone = phone;
    this.zipCode = zipCode;
    this.isDefault = isDefault;
  }

  static Hydrate(object): Address {
    const c: Country = Country.Hydrate(object.country);

    // tslint:disable-next-line:max-line-length
    return new Address(object.id, object.address, object.city, object.company, c, object.firstname, object.gender, object.label, object.lastname, object.mobile, object.phone, object.zipCode, object.isDefault);
  }

  isCompany(): boolean {
    return this.gender === 'COMPANY';
  }

  isValidForSignup(): boolean {
    if (!this.gender) {
      return false;
    }

    if (this.isCompany() && !this.company) {
      return false;
    }

    if (!this.firstname) {
      return false;
    }

    if (!this.lastname) {
      return false;
    }

    if (!this.label) {
      return false;
    }

    if (!this.address) {
      return false;
    }

    if (!this.zipCode) {
      return false;
    }

    if (!this.city) {
      return false;
    }

    if (!this.country.id) {
      return false;
    }

    if (!this.mobile) {
      return false;
    }

    return true;
  }

  zipCodeStartWith(department: string): boolean {
    return this.zipCode.startsWith(department);
  }
}
